import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { postData } from '../../../adapters/coreservices';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../widgets/pageTitle';

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation(); // For translations
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertMsg, setAlertMsg] = useState({ message: '', alertBoxColor: '' });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t('validation.invalidEmail'))
        .required(t('validation.requiredEmail')),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleFormData(values, { setSubmitting });
    },
  });

  const handleFormData = (values: any, { setSubmitting }: any) => {
    postData('/public/forgotpassword', values, undefined, false)
      .then((res: any) => {
        if (res.status === 200) {
          setAlertStatus(true);
          setAlertMsg({
            message: t('signup.successMessage'),
            alertBoxColor: 'alert-success',
          });
        } else {
          setAlertStatus(true);
          setAlertMsg({
            message: t('signup.errorMessage'),
            alertBoxColor: 'alert-danger',
          });
        }
      })
      .catch((err: any) => {
        // Check if the error is a 400 and display the error message
        if (err.response && err.response.status === 400) {
          setAlertStatus(true);
          setAlertMsg({
            message: err.response.data.message, // Show the error message from the response
            alertBoxColor: 'alert-danger',
          });
        } else {
          setAlertStatus(true);
          setAlertMsg({
            message: t('signup.errorMessage'),
            alertBoxColor: 'alert-danger',
          });
        }
      })
      .finally(() => {
        setSubmitting(false); // Allow submit button to be re-enabled
      });
  };
  
  return (
    <>
    <div className="contentarea-wrapper">
      {/* <h2 className="page-title db-title">{t('forgotPassword.title')}</h2> */}
      <PageTitle pageTitle={t('forgotPassword.title')} gobacklink="/" />
      <div className="line-lessmargin"></div><br/>
      {/* Alert Message */}
      {alertStatus && (
        <div className={`alert ${alertMsg.alertBoxColor}`} role="alert">
          {alertMsg.message}
        </div>
      )}

      <form onSubmit={formik.handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">{t('forgotPassword.emailLabel')}</label>
          <input
            id="email"
            name="email"
            type="email"
            className="form-control inputWidth"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-danger">{formik.errors.email}</div>
          ) : null}
        </div>
        <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? t('forgotPassword.sending') : t('forgotPassword.sendLink')}
        </button>
      </form>
    </div>
    </>
  );
};

export default ForgotPassword;
