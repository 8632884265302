import { configureStore } from '@reduxjs/toolkit';
import userInfo from './slices/userInfo';
import globalAlertsReducer from './slices/globalAlerts'; // Import your other slices
import instituteReducer from './slices/institute'; // Import your other slices

// Create and configure the store
const store = configureStore({
  reducer: {
    user: userInfo,
    globalAlerts: globalAlertsReducer, // Add your other reducers here
    institute: instituteReducer
  },
});

// Define RootState and AppDispatch types for use throughout the app
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
