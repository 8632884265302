import { useState, useEffect } from "react";
import TimerAlertBox from "../../widgets/alert/timerAlert";



const GlobalErrorAlert:any = ({ error }:any, holdFalg?:boolean) => {
    const [showAlert, setShowAlert] = useState(true); // State to control alert visibility

    let message = "Server error occurred";
    let alertBoxColor = "danger"; // Default color for error alerts
    console.log(error)
    // Handle HTTP status codes
    if (error.response && error.response.status || error.status) {
        switch (error.response.status||error.status) {
            case 200:
                message = "Request was successful"; // example message for success
                alertBoxColor = "success"; // success color for alert
                break;
                case 400:
                    message = error.response.data?.message || "Bad Request";
                    break;
            case 401:
                message = error.response.data?.message || "Unauthorized";
                break;
                case 403:
                    message = error.response.data?.message || "Forbidden";
                    break;
                    case 404:
                        message = error.response.data?.message || "Resource not found";
                        break;
                        case 500:
                            message = error.response.data?.message || "Internal Server Error";
                            break;
            case 502:
                message = error.response.data?.message || "Bad Gateway";
                break;
                case {}:
                    message ="loading...";
                    break;
                default:
                    message = error.response.data?.message || "An unexpected error occurred";
                break;
            }
            
        } 
        // else {
        //     // Handle non-HTTP errors (like JavaScript errors)
        //     message = error.message || "An unexpected error occurred";
        // }
    // Hide the alert after 3 seconds
    useEffect(() => {
        if(!holdFalg){
        const timer = setTimeout(() => setShowAlert(false), 3000); // Hide after 3 seconds
        return () => clearTimeout(timer); // Cleanup timer on component unmount
        }
    }, []);
    // Only show the alert if `showAlert` is true
    return (
        showAlert && (
            <TimerAlertBox
                alertMsg={message}
                className="mt-3"
                variant={alertBoxColor}
                showAlert={showAlert}
            />
        )
    );
};

export default GlobalErrorAlert;
