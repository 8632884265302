import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userActions } from "../../store/slices/userInfo";
import { instituteActions } from "../../store/slices/institute";

type Props = {};

const Logout = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(userActions.logout());
    dispatch(instituteActions.clearInstituteData());
    navigate("/");  // Uncomment if you want to handle internal navigation instead of the external URL.
  }, []);

  // Return nothing since we are redirecting away from this component.
  return null;
};

export default Logout;
