import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Check if the user is logged in (check both Redux and localStorage)
const useAuth = () => {
  const isLoggedIn = useSelector((state: any) => state.user.loggedIn);
  
  // Check localStorage if Redux state is not available
  const localStorageLoggedIn = localStorage.getItem('loggedIn') === 'true';
  
  return isLoggedIn || localStorageLoggedIn; // Return true if either Redux or localStorage is logged in
};

function ProtectedRoutes() {
  const isAuth = useAuth();

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace />
  );
}

// Check if the user is an admin (check both Redux and localStorage)
const useAdmin = () => {
  const isLoggedIn = useSelector((state: any) => state.user.loggedIn);
  const isAdmin = useSelector((state: any) => state.user.isAdmin);
  return (isAdmin && (isLoggedIn ));
};

function ProtectedAdminRoutes() {
  const isAdmin = useAdmin();

  return isAdmin ? <Outlet /> : <Navigate to="/dashboard" replace />;
}

export { ProtectedRoutes, ProtectedAdminRoutes };
