import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";

interface ITimerAlertBox {
  alertMsg: string;
  variant: string;
  setShowAlert?: (params: boolean) => void; // Marked as optional
  showAlert?: boolean;
  className?: string;
}

const TimerAlertBox: React.FunctionComponent<ITimerAlertBox> = ({
  alertMsg,
  variant,
  setShowAlert,
  showAlert,
}: ITimerAlertBox) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (setShowAlert) {
        setShowAlert(false); // Only call setShowAlert if it's defined
      }
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [showAlert, setShowAlert]); // Include setShowAlert in dependency array for effect stability

  return (
    <React.Fragment>
      <div>
        {showAlert && (
          <Alert
            style={{ textAlign: "center" }}
            variant={variant}
            onClick={() => setShowAlert && setShowAlert(false)} // Again, check if setShowAlert is defined
            // dismissible
          >
            {alertMsg}
          </Alert>
        )}
      </div>
    </React.Fragment>
  );
};

export default TimerAlertBox;
