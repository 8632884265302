import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PageNotFound from '../../pages/404';
import MailTemplateConfiguration from '../../pages/mailTempelateConfiguration';
import { ProtectedRoutes } from './ProtectedRoutes';  // Your ProtectedRoutes logic
import RedirectToOAuth from '../../pages/redirectionToAuth/RedirectToOAuth';
import AuthLogin from '../../pages/authLogin/authLogin';
import ForgotPassword from '../../pages/signuppage/forgetPassword/forgetpassword';
import ResetPassword from '../../pages/signuppage/resetPassword/resetpassword';
import InstituteManagement from '../../pages/institute';
import Logout from '../../pages/logout';
import HeaderFooter from '../../pages/commonComponents/HeaderFooterLayout';
import DashboardApi from '../../pages/dashboard';
import UserManagement from '../../pages/userManagement/UserManagement';
import FAQ from '../../pages/FAQ/FaqQ/A';
import AdminFaqIndex from '../../pages/FAQ/adminFaq/manageFaq';
import AddFaqTopicIndex from '../../pages/FAQ/adminFaq/addFaqTopic';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<HeaderFooter />}> 
        <Route path="/" element={<RedirectToOAuth />} />
        <Route path="/authlogin" element={<AuthLogin />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/faq" element={<FAQ />} />


        {/* Wrap protected routes in ProtectedRoutes */}
        <Route element={<ProtectedRoutes />}>
          <Route path="/mailconfiguration" element={<MailTemplateConfiguration />} />
          <Route path="/dashboard" element={<DashboardApi />} />
          <Route path="/lmsconfig" element={<InstituteManagement />} />
          {/* Add other protected routes here */}
          <Route path="/usermanagement" element={<UserManagement />} />
          <Route path="/addfaqtopic" element={<AddFaqTopicIndex />} />
        <Route path="/adminfaq" element={<AdminFaqIndex />} />
        </Route>

        <Route path="/PageNotFound" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
