import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import config from '../../utils/config';

// Define the structure of each institute
interface Institute {
  id: number;
  name: string;
  shortCode: string;
  userEmail: string;
  userId: number;
  instanceUrl: string;
  webServiceToken: string; // Temporary in Redux state
  locked: boolean;
}

// Define the structure for pagination
interface Pager {
  totalPages: number;
  totalElements: number;
}

// Define the complete state structure that holds all the data as one object
interface InstituteState {
  instituteData: {
    items: Institute[];
    pager: Pager;
  };
}

// Safely parse saved data from localStorage
const getSavedInstituteData = (): InstituteState['instituteData'] => {
  try {
    const savedData = localStorage.getItem('instituteData');
    return savedData ? JSON.parse(savedData) : { items: [], pager: { totalPages: 0, totalElements: 0 } };
  } catch (error) {
    console.error('Error parsing localStorage data:', error);
    return { items: [], pager: { totalPages: 0, totalElements: 0 } };
  }
};

// Initialize the state
const initialState: InstituteState = {
  instituteData: getSavedInstituteData(),
};

const instituteSlice = createSlice({
  name: 'institute',
  initialState,
  reducers: {
    // Set all institute data at once and persist it in localStorage (without webServiceToken)
    setInstituteData(state, action: PayloadAction<{ items: Institute[]; pager: Pager }>) {
      const { items, pager } = action.payload;
      state.instituteData = { items, pager };

      // Extract the first institute's token for temporary use
      const firstInstitute = items[0];
      if (firstInstitute) {
        config.WSTOKEN = firstInstitute.webServiceToken;
        // config.OAUTH2_ACCESS_TOKEN = firstInstitute.webServiceToken;
        localStorage.setItem('Wstoken', firstInstitute.webServiceToken);
      }

      // Save the full instituteData (without sensitive tokens) in localStorage
      localStorage.setItem('instituteData', JSON.stringify({ items, pager }));
    },

    // Clear all institute data and tokens from Redux state and localStorage
    clearInstituteData(state) {
      state.instituteData = {
        items: [],
        pager: { totalPages: 0, totalElements: 0 },
      };

      // Clear from localStorage
      localStorage.removeItem('instituteData');
      localStorage.removeItem('Wstoken');

      // Clear temporary tokens in memory
      // config.WSTOKEN = '';
      // config.OAUTH2_ACCESS_TOKEN = '';
    },
  },
});

export const instituteActions = instituteSlice.actions;
export default instituteSlice.reducer;
