import React, { useMemo } from "react";
import { Table, Button, Alert } from "react-bootstrap";
import GlobalErrorAlert from "../../../../globals/GlobalErrorFunction";

interface FAQTableProps {
  tableData: {
    sno: number;
    topic: string;
    question: string;
    answer: string;
    published: boolean;
    id: number;
  }[];
  error:any;
  isLoading: string;
  onEdit: (data: any, type:string) => void;
  onDelete: (id: number) => void;
}

const FAQTable: React.FC<FAQTableProps> = ({ tableData, onEdit, onDelete, error,isLoading }) => {
  const columns = useMemo(
    () => [
      { Header: "S.No", accessor: "sno" },
      { Header: "Topic", accessor: "topic" },
      { Header: "Question", accessor: "question" },
      { Header: "Answer", accessor: "answer" },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }: any) => (
          <div className="d-flex gap-2">
            <Button
              variant="primary"
              size="sm"
              onClick={() => onEdit(row, "edit")}
            >
              Edit
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => onDelete(row.faqId)}
            >
              Delete
            </Button>
          </div>
        ),
      },
    ],
    [onEdit, onDelete]
  );

  return (
    <Table responsive className="generaltable table-dark-header">
      <thead>
        <tr>
          {columns.map((col, index) => (
            <th key={index}>{col.Header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.length > 0 ? (
          tableData.map((row, index) => (
            <tr key={index}>
              {columns.map((col, colIndex) => (
                <td key={colIndex}>
                  {col.accessor === "action"
                    ? col.Cell?.({ row })
                    : row[col.accessor as keyof typeof row]}
                </td>
              ))}
            </tr>
          ))
        ) : (
          <>
          {isLoading === "true" ? (
            <tr>
              <td colSpan={6} className="text-center">{"Loading..."}</td>
            </tr>
          ) : Object.keys(error).length > 0 ? (
            <GlobalErrorAlert error={error} holdFlag={true} />
          ) : (
            <tr>
              <td colSpan={6} className="text-center">{"No users found"}</td>
            </tr>
          )}
        </>
        )}
      </tbody>
    </Table>
  );
};

export default FAQTable;
