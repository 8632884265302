import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Nav, Tab, Accordion } from "react-bootstrap";
import Errordiv from '../../../../widgets/alert/errordiv';

interface FAQ {
    id: number;
    question: string;
    answer: string;
    published: boolean;
}

interface Item {
    id: number;
    name: string;
    published: boolean;
    faqs: FAQ[];
}

interface FAQData {
    pager: {
        totalPages: number;
        totalElements: number;
    };
    items: Item[];
}

function FaqPage({ faqData, isLoading }: { faqData: FAQData , isLoading:boolean}) {
    const { t } = useTranslation();

    return (
        <Container fluid>
            {/* Use a Fragment to wrap multiple children */}
            <>
                {faqData.items.length > 0 ? (
                    <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey={faqData.items[0]?.id.toString()}
                    >
                        <Row className="mt-4">
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    {faqData.items.map((item) => (
                                        <Nav.Item key={item.id}>
                                            <Nav.Link eventKey={item.id.toString()}>
                                                {item.name}
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    {faqData.items.map((item) => (
                                        <Tab.Pane eventKey={item.id.toString()} key={item.id}>
                                            <Accordion defaultActiveKey="0" flush>
                                                {item.faqs.map((faq, index) => (
                                                    <Accordion.Item
                                                        eventKey={index.toString()}
                                                        key={faq.id}
                                                    >
                                                        <Accordion.Header>
                                                            {faq.question}
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            {faq.answer}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                            </Accordion>
                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                ) : (
                    // Show the error message when no items are available and loading is false
                    !isLoading && faqData.items.length === 0 && (
                        <Errordiv msg={t('common.noRecord')} cstate className="mt-3" />
                    )
                )}
            </>
        </Container>
    );
}

export default FaqPage;
