import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import bellicon from "../../../../assets/images/bellicon.png";
import ltcicon from "../../../../assets/images/ltcicon.png";
import home from "../../../../assets/images/home.png";
import ahome from "../../../../assets/images/ahome.png";
import course from "../../../../assets/images/course.png";
import academicection from "../../../../assets/images/academicection.png";
import examsection from "../../../../assets/images/examsection.png";
import setfacility from "../../../../assets/images/setfacility.png";
import faq from "../../../../assets/images/faq.png";
import axios from 'axios';
import { getData } from '../../../../adapters/moodleServices';



interface SidebarNotificationData {
  totalnotificationcount: number|any;
  ltcnotificationcount: number|any;
  htmldata: string|any;
}

interface SidebarMenuData {
  htmldata: string|any;
}

const SidebarContent = () => {
    const [sidebarNotificationData, setSidebarNotificationData] = useState<SidebarNotificationData | null|any>(null);
  const [sidebarMenu, setSidebarMenu] = useState<SidebarMenuData | null>(null);
  const isAdmin = useSelector((state: any) => state.user.isAdmin);
  const isLoggedIn = useSelector((state: any) => state.user.loggedIn);
  const userid: number | null = useSelector((state: any) => state.user.userId);


  useEffect(() => {
    const fetchSidebarMenu = async () => {
      const query1 = { wsfunction: 'local_seatcreation_sidebar_menu'};
      try {
        const res = await getData(query1);
        if (res.status === 200 && res.data && !res.data.errorcode) {
          setSidebarMenu(res.data as SidebarMenuData);
        }
      } catch (err) {
        console.error(err);
      }
    };

    //================================================================
            // not in use 
     // // new api to use after moodle work on it
      // const url = 'https://saral.learn.ballisticlearning.com/lib/ajax/service-nologin.php';

      // // Define the request payload
      // const data:any = [
      //   {
      //     index: 0,
      //     methodname: 'local_seatcreation_sidebar_menu',
      //     args: {}
      //   }]
      // ;

      // Make the POST request
      // axios.post(url, data)
      //   .then(response => {
      //     setSidebarMenu(response.data as SidebarMenuData);
      //   })
      //   .catch(error => {
      //     console.error('Error:', error);
      //   });
//================================================================
    const fetchSidebarNotifications = async () => {
      const query = { wsfunction: 'local_seatcreation_get_prendingtask_notifications', userid };
      try {
        const res = await getData(query);
        if (res.status === 200 && res.data && !res.data.errorcode) {
          setSidebarNotificationData(res.data as SidebarNotificationData);
        }
      } catch (err) {
        console.error(err);
      }
    };

    
    if (isLoggedIn) {
      fetchSidebarNotifications();
      fetchSidebarMenu();
    }
  }, [isLoggedIn, userid]);

  return (
    <div id="verticalMainMenu" className="mainmenu-container">
      {/* Sidebar Menu */}
      {isLoggedIn ? (
      sidebarMenu?.htmldata && <div dangerouslySetInnerHTML={{ __html: sidebarMenu.htmldata }} />
    ):

      (<ul className="menu">
               <li>
                 <a href="https://saral.learn.ballisticlearning.com/local/program_manager/index.php" className="hometab">
                   <div className="cus-white-box">
                     <img src={home} alt="Home" className="img-fluid" />
                     <h5>Home</h5>
                   </div>
                 </a>
               </li>
               <li>
                 <a href="https://saral.learn.ballisticlearning.com/local/seatcreation/index.php" className="ahometab">
                   <div className="cus-white-box">
                     <img src={ahome} alt="A Home" className="img-fluid" />
                     <h5>Saral Home</h5>
                   </div>
                 </a>
               </li>
               <li>
                 <a href="https://saral.learn.ballisticlearning.com/blocks/teaching_schedule/index.php" className="course">
                   <div className="cus-white-box">
                     <img src={academicection} alt="Course" className="img-fluid" />
                     <h5>Teaching Schedule</h5>
                   </div>
                 </a>
               </li>
               <li>
                 <a href="https://saral.learn.ballisticlearning.com/local/examschedule/index.php" className="academicsectiontab">
                   <div className="cus-white-box">
                     <img src={examsection} alt="Academic Section" className="img-fluid" />
                     <h5>Exam Schedule</h5>
                   </div>
                 </a>
               </li>
               <li>
                 <a href="#" className="examsectiontab">
                   <div className="cus-white-box">
                     <img src={course} alt="Exam Section" className="img-fluid" />
                     <h5>Course at Saral</h5>
                   </div>
                 </a>
               </li>
               <li>
                 <a href="#" className="setfacilitytab">
                   <div className="cus-white-box">
                     <img src={setfacility} alt="Set Facility" className="img-fluid" />
                     <h5>Set Facility</h5>
                   </div>
                 </a>
               </li>
               <li>
                 <a href="/faq" className="faqtab">
                   <div className="cus-white-box">
                     <img src={faq} alt="Faq" className="img-fluid" />
                     <h5>FAQ’s</h5>
                   </div>
                 </a>
               </li>
             </ul>)
              }

      {/* Notification Section */}
      {isLoggedIn && (
        <div id="verticalMainBell" className="MainMenuBell">

               <div id="bellBox" className="cus-bell-box">

                 <div className="bellAlert">
                   {sidebarNotificationData?.totalnotificationcount > 0 && <span className="countStatus">sidebarNotificationData?.totalnotificationcount</span>}
                   <img src={bellicon} alt="Notification" className="img-fluid bell"/>
                 </div>

                 {sidebarNotificationData?.ltcnotificationcount > 0 && 
                  <div className="ltcAlert">
                   <span className="countStatus">sidebarNotificationData?.ltcnotificationcount</span>
                   <img src={ltcicon} alt="LTC" className="img-fluid" />
                 </div>
                  }
               </div>
                            
                <div className="cus-todo-block" dangerouslySetInnerHTML={{ __html: sidebarNotificationData?.htmldata }} >
                  
                </div>
               
        </div>
      )}
    </div>
  );
};

export default SidebarContent;
