import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// UserState Interface
interface UserState {
  token: string | null;
  loggedIn: boolean;
  userInfo: UserInfo | null;
  isAdmin: boolean;
  authCode: string;
  userId: string | null;  // Added userId field to state
}

interface UserInfo {
  systemRoles: Record<string, { shortName: string }>;
  institutes?: Record<string, { idNumber: string }>;  // Assuming institutes is part of userInfo
}

// Initial State
const initialState: UserState = {
  token: localStorage.getItem('userToken') || null,  // Load token from localStorage
  loggedIn: localStorage.getItem('loggedIn') === 'true',  // Check if logged in based on token presence
  userInfo: JSON.parse(localStorage.getItem('userInfo') || 'null'),  // Load user info from localStorage
  isAdmin: localStorage.getItem('isAdmin') === 'true',  // Load isAdmin from localStorage (stored as a string)
  authCode: localStorage.getItem('authCode') || '',  // Load authCode from localStorage
  userId: localStorage.getItem('userId') || "",  // Load userId from localStorage
};

// User Slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Set User Token and User Info
    setUserToken(state, action: PayloadAction<{ token: string; userInfo: UserInfo }>) {
      state.token = action.payload.token;
      state.userInfo = action.payload.userInfo;
      state.loggedIn = true;

      // Extract userId from institutes object
      const userId = action.payload.userInfo?.institutes?.["1"]?.idNumber || null;
      state.userId = userId;

      // Check if user is an admin
      const isAdmin = Object.values(action.payload.userInfo?.systemRoles || {}).some(
        (role) => role.shortName === 'admin'
      );
      state.isAdmin = isAdmin;

      // Persist values to localStorage
      localStorage.setItem('userToken', action.payload.token);
      localStorage.setItem('userInfo', JSON.stringify(action.payload.userInfo));
      localStorage.setItem('isAdmin', String(isAdmin));
      localStorage.setItem('userId', userId || '');  // Persist userId
      localStorage.setItem('loggedIn', "true");
    },

    // Set Logged In State
    setLoggedIn(state, action: PayloadAction<boolean>) {
      state.loggedIn = action.payload;
      localStorage.setItem('loggedIn', String(action.payload));  // Persist the boolean as a string ('true' or 'false')
    },

    // Set User Info
    setUserInfo(state, action: PayloadAction<UserInfo>) {
      state.userInfo = action.payload;
      const isAdmin = Object.values(action.payload?.systemRoles || {}).some(
        (role) => role.shortName === 'admin'
      );
      state.isAdmin = isAdmin;
      localStorage.setItem('userInfo', JSON.stringify(action.payload));
      localStorage.setItem('isAdmin', String(isAdmin));
    },

    // Set Admin State
    setUserAdmin(state, action: PayloadAction<boolean>) {
      state.isAdmin = action.payload;
      localStorage.setItem('isAdmin', String(action.payload));
    },
    setUserId(state, action: PayloadAction<string | null>) {
      state.userId = action.payload;
      localStorage.setItem("userId", action.payload || ""); // Persist userId
    },

    // Logout - Remove all persistent data
    logout(state) {
      state.token = null;
      state.userInfo = null;
      state.loggedIn = false;
      state.isAdmin = false;
      state.authCode = "";
      state.userId = null;  // Clear userId in state

      // Clear session/local storage
      sessionStorage.removeItem('userToken');
      sessionStorage.removeItem('authCode');
      sessionStorage.removeItem('access_token');
      localStorage.removeItem('loggedIn');
      localStorage.removeItem('userToken');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('isAdmin');
      localStorage.removeItem('userId');  // Clear userId from localStorage
      localStorage.removeItem('authCode');
      localStorage.removeItem('access_token');
      localStorage.removeItem('Wstoken');
      localStorage.clear();
    },

    // Set Auth Code
    setAuthCode(state, action: PayloadAction<string>) {
      state.authCode = action.payload;
      localStorage.setItem('authCode', action.payload);
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
