export const getInitial = (user:any) => {
  if(user?.first_name && user?.first_name.length > 0 && user?.last_name && user?.last_name.length > 0){
    return user?.first_name[0].toUpperCase() + user?.last_name[0].toUpperCase();
  }
    else if (user?.first_name && user?.first_name.length > 0) {
      return user?.first_name[0].toUpperCase();
    } else if (user?.last_name && user?.last_name.length > 0) {
      return user?.last_name[0].toUpperCase();
    }
    return ''; // If neither is available, return an empty string
  };