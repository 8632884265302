import * as Yup from "yup";
import Swal from "sweetalert2";
import { Formik, Form } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "sweetalert2/src/sweetalert2.scss";
import { useTranslation } from "react-i18next";
// import CountryList from "../../../globals/country";
import TimerAlertBox from "../../widgets/alert/timerAlert";
import FieldLabel from "../../widgets/formInputFields/labels";
import CustomButton from "../../widgets/formInputFields/buttons";
// import WaveBottom from "../../../assets/images/background/bg-modal.svg";
import { LoadingButton } from "../../widgets/formInputFields/buttons";
import FieldTypeText from "../../widgets/formInputFields/formTextField";
import FieldErrorMessage from "../../widgets/formInputFields/errorMessage";
import FieldTypeSelect from "../../widgets/formInputFields/formSelectField";
import { TAddUserModalProps, TAlertMsg, TInitialValuesOmit } from "./typescript";
import { useSelector } from "react-redux";
import { postData, putData } from "../../adapters/coreservices";

const AddUserModal: React.FC<TAddUserModalProps> = (props) => {
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState<TAlertMsg>({ message: "", alertBoxColor: "" });

//   const currentUserRole = useSelector(
//     (state: any) => state.globalFilters.currentUserRole
//   );

  const isAdmin = useSelector((state: any) => state.user.isAdmin);

  const initialValues: TInitialValuesOmit = {
    userFirstName: props.userobj.userFirstName || "",
    userLastName: props.userobj.userLastName || "",
    userEmail: props.userobj.userEmail || "",
    userCountry: props.userobj.userCountry || "",
    enabled: props.userobj.enabled || true,
    ...(props.userobj.idNumber && { idNumber: props.userobj.idNumber }) 
    // shouldValidatePassword: props.userobj.userId > 0 ? false : true,
  };

  // Formik Yup validation
  const userFormSchema = Yup.object({
    userEmail: Yup.string()
      .email(t('common.invalidEmail'))
      .required(t('common.emailReq')),
    userFirstName: Yup.string()
      .test(
        t('userMgt.chatAlowed'),
        t('userMgt.specificCharactersAllowed'),
        function (value: any) {
          return /^[A-Za-z]+$/.test(value);
        }
      )
      .trim()
      .required(t('common.fNameReq')),
    userLastName: Yup.string()
      .test(
        t('userMgt.chatAlowed'),
        t('userMgt.specificCharactersAllowed'),
        function (value: any) {
          return /^[A-Za-z]+$/.test(value);
        }
      )
      .trim()
      .required(t('common.lNameReq')),
    // userCountry: Yup.string().required(t('common.countryReq')),
    ...(isAdmin && props.userobj?.idNumber &&{
      idNumber: Yup.string().required(t('userMgt.idNumberReq')),
    }),    
  });

  // Handle form data (Add or Edit)
  const handleFormData = (values: {}, { setSubmitting, resetForm }: any) => {
    setSubmitting(true);
    if (props.userobj.userId === 0) {
      postData(`/${props.currentInstitute}/users`, values)
        .then((res: any) => {
          if ((res.data !== "", res.status === 201)) {
            props.togglemodalshow(false);
            setSubmitting(false);
            Swal.fire({
              timer: 3000,
              width: "25em",
              color: "#666",
              icon: "success",
              background: "#e7eef5",
              showConfirmButton: false,
              text: t('userMgt.userSuccAdded')
            });
            resetForm();
          }
        })
        .catch((err: any) => {
          console.log(err);
          if (err.response.status === 404 || 400) {
            setSubmitting(false);
            setShowAlert(true);
            setAlertMsg({
              message: `${err.response.data.message}. Please Sign up with a new email`,
              alertBoxColor: "danger",
            });
          }
        });
    } else {
      setSubmitting(true);
      putData(`/${props.currentInstitute}/users/${props.userobj.userId}`, values)
        .then((res: any) => {
          if ((res.data !== "", res.status === 200)) {
            props.togglemodalshow(false);
            setSubmitting(false);
            Swal.fire({
              timer: 3000,
              width: "25em",
              color: "#666",
              icon: "success",
              background: "#e7eef5",
              showConfirmButton: false,
              text: t('userMgt.userSuccUpdated')
            });
          }
        })
        .catch((err: any) => {
          setSubmitting(false);
          setShowAlert(true);
          setAlertMsg({
            message: `${err.response.data.message} Please Update with a new email id`,
            alertBoxColor: "danger",
          });
        });
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-design-wrapper"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.userobj.userId === 0 ? t('userMgt.addUser') : t('userMgt.updateUser')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TimerAlertBox
            alertMsg={alertMsg.message}
            className="mt-3"
            variant={alertMsg.alertBoxColor}
            setShowAlert={setShowAlert}
            showAlert={showAlert}
          />
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={userFormSchema}
            onSubmit={(values, action) => {
              handleFormData(values, action);
            }}
          >
            {({ errors, touched, isSubmitting, setValues, values }) => (
              <Form>
                <div className="mb-3">
                  <FieldLabel
                    htmlFor="userFirstName"
                    labelText={t('common.firstName')}
                    required="required"
                    star="*"
                  />
                  <FieldTypeText
                    name="userFirstName"
                    placeholder={t('common.firstName')}
                  />
                  <FieldErrorMessage
                    errors={errors.userFirstName}
                    touched={touched.userFirstName}
                    msgText={t('common.fNameReq')}
                  />
                </div>

                <div className="mb-3">
                  <FieldLabel
                    htmlFor="userLastName"
                    labelText={t('common.lastName')}
                    required="required"
                    star="*"
                  />
                  <FieldTypeText name="userLastName" placeholder={t('common.lastName')} />
                  <FieldErrorMessage
                    errors={errors.userLastName}
                    touched={touched.userLastName}
                    msgText={t('common.lNameReq')}
                  />
                </div>

                <div className="mb-3">
                  <FieldLabel
                    htmlFor="userEmail"
                    labelText={t('common.email')}
                    required="required"
                    star="*"
                  />
                  <FieldTypeText name="userEmail" placeholder={t('common.email')} />
                  <FieldErrorMessage
                    errors={errors.userEmail}
                    touched={touched.userEmail}
                    msgText={t('common.emailReq')}
                  />
                </div>

                {/* <div className="mb-3">
                  <FieldLabel
                    htmlFor="userCountry"
                    labelText={t('common.country')}
                    required="required"
                    star="*"
                  />
                  <FieldTypeSelect
                    name="userCountry"
                    options={CountryList}
                    setcurrentvalue={setValues}
                    currentformvalue={values}
                    selectDefaultLabel={t('common.country')}
                  />
                  <FieldErrorMessage
                    errors={errors.userCountry}
                    touched={touched.userCountry}
                    msgText={t('common.selectCountry')}
                  />
                </div> */}

                {isAdmin && props.userobj?.idNumber && (
                  <div className="mb-3">
                    <FieldLabel
                      htmlFor="idNumber"
                      labelText={t('common.moodleId')}
                      required="required"
                      star="*"
                    />
                    <FieldTypeText
                      name="idNumber"
                      placeholder={t('common.moodleId')}
                    />
                    <FieldErrorMessage
                      errors={errors.idNumber}
                      touched={touched.idNumber}
                      msgText={t('userMgt.idNumberReq')}
                    />
                  </div>
                )}

                {isSubmitting === false ? (
                  <div className="modal-buttons">
                    <CustomButton
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                      btnText={props.userobj.userId === 0 ? t('common.submit') : t('common.update')}
                    />{" "}
                    {props.userobj.userId === 0 && (
                      <CustomButton
                        type="button"
                        variant="secondary"
                        onClick={() => props.togglemodalshow(false)}
                        btnText={t('common.cancel')}
                      />
                    )}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <LoadingButton />
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AddUserModal;
