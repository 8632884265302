import * as Yup from "yup";
import { Formik, Form } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import CustomButton from "../../widgets/formInputFields/buttons";
import FieldTypeText from "../../widgets/formInputFields/formTextField";
import FieldErrorMessage from "../../widgets/formInputFields/errorMessage";
import { putData } from "../../adapters/coreservices";

// Initial form values
const initialValues = {
  newPassword: "",
  confirmPassword: "",
  currentPassword: "",
};

// Validation schema using Yup
const formSchema = Yup.object({
  newPassword: Yup.string().required("New password is required"),
  currentPassword: Yup.string().required("Current password is required"),
  confirmPassword: Yup.string()
    .required("Please confirm your new password")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});

// Main ChangePassword Component
const ChangePassword = ({
  showModal,
  setShowModal,
  // updateAddRefresh,
}: {
  showModal: any;
  setShowModal: any;
  // updateAddRefresh: any; // Function to refresh data after successful update
}) => {
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    message: "",
    alertBoxColor: "",
  });

  // Handle form submission with API call
  const handleFormData = async (values: any, { setSubmitting, resetForm }: any) => {
    const endPoint = "user/profile/change-password";

    setSubmitting(true);

    try {
      const response = await putData(endPoint, values);
      if (response.status === 200) {
        setShowModal(false);
        // updateAddRefresh(); // Refresh data
        setAlertMsg({
          message: t("userProfile.passwordUpdateSuccess"),
          alertBoxColor: "alert-success",
        });
        resetForm();
      } else {
        setShowAlert(true);
        setAlertMsg({
          message: t("userProfile.resetPassRequest"),
          alertBoxColor: "alert-danger",
        });
      }
    } catch (error: any) {
      setShowAlert(true);

      if (error.response?.status === 400) {
        setAlertMsg({
          message: error.response.data.message,
          alertBoxColor: "alert-danger",
        });
      } else {
        setAlertMsg({
          message: t("userProfile.resetPassRequest"),
          alertBoxColor: "alert-danger",
        });
      }

      console.error("Error updating password:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const hideAlertModal = () => { setShowModal(false); setShowAlert(false)}

  return (
    <Modal show={showModal} onHide={() => hideAlertModal()}>
      <Modal.Header closeButton>
        <Modal.Title>{t("userProfile.changePass")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {showAlert && (
          <div className={`alert ${alertMsg.alertBoxColor}`} role="alert">
            {alertMsg.message}
          </div>
        )}

        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={handleFormData}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <div className="mb-3">
                <label htmlFor="currentPassword" className="form-label">
                  {t("userProfile.currentPass")}
                </label>
                <FieldTypeText
                  type="password"
                  name="currentPassword"
                  placeholder={t("userProfile.currentPass")}
                />
                <FieldErrorMessage
                  errors={errors.currentPassword}
                  touched={touched.currentPassword}
                  msgText={t("userProfile.enterCurrentPass")}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="newPassword" className="form-label">
                  {t("userProfile.newPass")}
                </label>
                <FieldTypeText
                  type="password"
                  name="newPassword"
                  placeholder={t("userProfile.newPass")}
                />
                <FieldErrorMessage
                  errors={errors.newPassword}
                  touched={touched.newPassword}
                  msgText={t("userProfile.enterNewPass")}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label">
                  {t("userProfile.confirmNewPass")}
                </label>
                <FieldTypeText
                  type="password"
                  name="confirmPassword"
                  placeholder={t("userProfile.confirmNewPass")}
                />
                <FieldErrorMessage
                  errors={errors.confirmPassword}
                  touched={touched.confirmPassword}
                  msgText={t("userProfile.reEnterPassMatchNewPass")}
                />
              </div>

              <div className="modal-footer">
                <Button
                  variant="secondary"
                  onClick={() => setShowModal(false)}
                >
                  {t("common.cancel")}
                </Button>
                <CustomButton
                  type="submit"
                  variant="primary"
                  disabled={isSubmitting}
                  btnText={t("common.update")}
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePassword;
