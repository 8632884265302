import React from "react";
import "./style.scss";
import "./mobileStyle.scss";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faTurnUp } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PageTitle = ({pageTitle, gobacklink, noTitle}:any) => {
    const navigate = useNavigate();
   const {t}=  useTranslation()
    return (
      <h2 className={`page-title ${noTitle}`}>
        <div dangerouslySetInnerHTML={{ __html: pageTitle }}></div>
        {
          gobacklink !== "" && 
          <Button
              size="sm"
              variant="light"
              onClick={() => navigate(gobacklink)}
          >
            {t('common.goBack')} <FontAwesomeIcon className="ms-1" icon={faTurnUp} /> 
          </Button>
        }
      </h2>
    );
  };
  
  export default PageTitle;