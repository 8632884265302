import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import FAQTable from "./FAQTable";
import FAQEditModal from "./FAQEditModal";
import { Alert, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { deleteData, postData } from "../../../../adapters/coreservices";
import PageTitle from "../../../../widgets/pageTitle";
import Swal from "sweetalert2";
import DeleteAlert from "../../../../widgets/alert/deleteAlert";
import BuildPagination from "../../../../widgets/pagination";
import { pagination } from "../../../../utils/pagination";
import CommonFilter from "../../../../globals/filters/commonFilter";

// Step 1: Define Types
interface FAQ {
  id: number;
  question: string;
  answer: string;
  published: boolean;
  topicId: number;
}

interface FAQItem {
  id: number;
  name: string;
  faqs: FAQ[];
}

interface FAQData {
  pager: {
    totalPages: number;
    totalElements: number;
  };
  items: FAQItem[];
}

interface EditData {
  question: string;
  answer: string;
  published: boolean;
  topicId: number | any;
}

const FAQManager = () => {
  const [filterUpdate, setFilterUpdate] = useState({ pageNumber: 0, pageSize: pagination.PERPAGE, categoryId:"" });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [faqData, setFaqData] = useState<FAQData>({ 
    pager: { totalPages: 0, totalElements: 0 }, 
    items: []
  });
  const [isLoading, setIsLoading] = useState("false");
  const [error, setError] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState<EditData | any>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState<number | null>(null);

  // Fetch FAQs data
  useEffect(() => {
    setIsLoading("true");
    axios
      .get("https://api.saral.ballisticlearning.com/core-service/api/v1/public/get-all-faqs", {
        params: filterUpdate,
      })
      .then((result) => {
        if (result.data && result.status === 200) {
          setFaqData(result.data);
        }
        setIsLoading("false");
      })
      .catch((err) => {
        setError(err);
        setFaqData({ 
          pager: { totalPages: 0, totalElements: 0 }, 
          items: []
        });
        setIsLoading("false");
      });
  }, [filterUpdate]);

  // Transform API Data to Table Format
  const transformData = (data: FAQData) => {
    const tableData: any = [];
    let sno = 1;

    data.items.forEach((item) => {
      item.faqs.forEach((faq) => {
        tableData.push({
          sno,
          topic: item.name,
          question: faq.question,
          answer: faq.answer,
          published: faq.published,
          faqId: faq.id,
          topicId: item.id,
        });
        sno++;
      });
    });

    return tableData;
  };

  // Refresh FAQ Data
  const handleRefreshPage = () => {
    setFilterUpdate({ ...filterUpdate });
  };

  // Handle Edit or Add FAQ
  const handleEdit = (data: any, type: string) => {
    setType(type);
    setShowEditModal(true);

    if (type === "edit") {
      // Prepare data for editing
      setEditData({
        ...data,
        question: data.question,
        answer: data.answer,
        published: data.published,
        topicId: data.topicId,
      });
    } else {
      // Prepare data for adding a new FAQ
      prepareAddData();
    }
  };

  const prepareAddData = () => {
    setEditData({
      question: "",
      answer: "",
      published: false,
      topicId: null,
    });
  };

  // Handle Delete
  const handleDelete = (id: number) => {
    setCurrentDeleteId(id);
    setShowDeleteModal(true);
  };

  // Submit Edit Data
  const handleSubmitEdit = () => {
    if (!editData || editData.topicId == null || !editData.question || !editData.answer) {
      // alert("Please fill in all fields.");
      return;
    }
    const payload = {
      id: editData.faqId,
      answer: editData.answer,
      question: editData.question,
      published: editData.published,
    };

    postData(`faq/${editData.topicId}/create-faq`, payload)
      .then((res) => {
        if (res.data && res.status === 201) {
          setShowEditModal(false);
          handleRefreshPage();
          Swal.fire({
            timer: 3000,
            width: "25em",
            color: "#666",
            icon: "success",
            background: "#e7eef5",
            showConfirmButton: false,
            text: "Question is added successfully",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        // Handle error gracefully
      });
  };

  const tableData = transformData(faqData);

  const handleClose = () => {
    setShowEditModal(false);
    handleRefreshPage(); // Refresh data after modal close
  };

  // Delete the FAQ from the database
  const deleteFAQ = async () => {
    try {
      const res = await deleteData(`faq/${currentDeleteId}`);
      if (res.data && res.status === 200) {
        handleRefreshPage();
        Swal.fire({
          timer: 3000,
          width: "25em",
          color: "#666",
          icon: "success",
          background: "#e7eef5",
          showConfirmButton: false,
          text: res.data,
        });
        setShowDeleteModal(false);
        setCurrentDeleteId(null);
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue deleting the FAQ.",
      });
      setShowDeleteModal(false);
      setCurrentDeleteId(null);
    }
  };
  // Trigger delete action if confirmed
  const deleteActionResponse = (action: string) => {
    if (action === t('common.yes')) {
      deleteFAQ();
    } else {
      setShowDeleteModal(false);
    }
  };

  const newPageRequest: (pageRequest: number) => void = (pageRequest: number) => {
    setFilterUpdate({ ...filterUpdate, pageNumber: pageRequest });
  };

  const topicsDropdown = useMemo(() => {
    const uniqueTopics = [
      ...new Set(
        faqData.items.map((item: FAQItem) =>
          JSON.stringify({ value: item.id, name: item.name })
        )
      ),
    ].map((item: string) => JSON.parse(item));
    
    return uniqueTopics;
  }, [faqData.items]);

  const fields = [
    { name: 'categoryId', label: 'Topic', type: 'select', options: topicsDropdown },
  ];
console.log(topicsDropdown);
  const handleFilterUpdate = (update: any) => {
    console.log('handleFilterUpdate',update)
    setFilterUpdate({ ...filterUpdate, categoryId: update.categoryId  });
  }
  console.log(topicsDropdown)
  

  return (
    <div>
      <div className="contentarea-wrapper">
        <PageTitle pageTitle={t("FAQ.adminFaqTitle")} gobacklink="" />

        <CommonFilter updateFilters={handleFilterUpdate} apiStatus={isLoading} fields={fields} />

        <div className="action-btn pb-2 text-md-end">
          <Button variant="primary" onClick={() => navigate("/addfaqtopic")}>
            View Topic
          </Button>

          {faqData.items.length > 0 ? (
            <Button variant="primary" onClick={() => handleEdit(editData, "update")}>
              Add Question
            </Button>
          ) : (Object.keys(error).length < 0 &&
            <Alert key={"primary"} variant={"primary"}>
              No topic is available. Add a topic first.
            </Alert>
          )}
        </div>
          <div className="admin-table-wrapper">
            <FAQTable 
              tableData={tableData}
              onEdit={handleEdit}
              onDelete={handleDelete}
              error={error}
              isLoading={isLoading} />
          </div>
        
        <BuildPagination 
          getrequestedpage={newPageRequest}                
          activepage={filterUpdate.pageNumber}                 
          totalpages={faqData.pager.totalPages}
        />

        <FAQEditModal
          show={showEditModal}
          onClose={handleClose} // Pass handleClose here
          editData={editData}
          setEditData={setEditData}
          onSubmit={handleSubmitEdit}
          type={type}
          topics={faqData.items.map((item) => ({ id: item.id, name: item.name }))}
        />
        <DeleteAlert
          show={showDeleteModal}
          modalHeading="Question"
          onHide={() => setShowDeleteModal(false)}
          deleteActionResponse={deleteActionResponse}
        />
      </div>
    </div>
  );
};

export default FAQManager;
