import HeaderFooterLayout from './HeaderFooterLayout';

function HeaderFooter() {
  return (
    <HeaderFooterLayout />
  );
}


export default HeaderFooter;
