import axios from 'axios';
import { axiosConfig, createCoreAxiosInstance } from "./utils";
// import { useSelector } from 'react-redux';

const ACCESS_TOKEN = sessionStorage.getItem("access_token") || localStorage.getItem("access_token") || ''; 

createCoreAxiosInstance(ACCESS_TOKEN); // to reset the instance if app is refreshed


export const getData = (endPoint, params, keepBearerToken = true) => {
    const instance = axiosConfig.axiosInstance;
    const modifiedInstance = axios.create(instance.defaults);

    const token = sessionStorage.getItem("access_token") || localStorage.getItem("access_token");
    if (token && keepBearerToken) {
        modifiedInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
    }

    return modifiedInstance.get(endPoint, { params });
};

export const postData = (endPoint, requestData, file, keepBearerToken = true) => {
    const instance = axiosConfig.axiosInstance;
    const data = requestData;

    const modifiedInstance = axios.create(instance.defaults);

    if (!keepBearerToken) {
        delete modifiedInstance.defaults.headers['Authorization'];
    }
    
    if (file !== undefined) {
        const headers = {};
        headers['Content-Type'] = 'multipart/form-data';

        const formData = new FormData();
        formData.append('file', file);
        Object.entries(data).forEach(([key, value]) => {
           formData.append(key, value);
        });

        return modifiedInstance.post(endPoint, formData, { headers });
    }

    return modifiedInstance.post(endPoint, data);
};

export const putData = (endPoint, requestData, keepBearerToken = true) => {
    const instance = axiosConfig.axiosInstance;
    const data = requestData;

    const modifiedInstance = axios.create(instance.defaults);

    // Modify headers if needed
    if (!keepBearerToken) {
        delete modifiedInstance.defaults.headers['Authorization'];
    }

    return modifiedInstance.put(endPoint, data);
};

export const deleteData = (endPoint, keepBearerToken = true) => {
    const instance = axiosConfig.axiosInstance;
    const modifiedInstance = axios.create(instance.defaults);

    // Modify headers if needed
    if (!keepBearerToken) {
        delete modifiedInstance.defaults.headers['Authorization'];
    }
    return modifiedInstance.delete(endPoint);
};
