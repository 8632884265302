import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { postData } from '../../../adapters/coreservices'; // Assuming this function sends the API request

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState<{ message: string; alertBoxColor: string }>({
    message: '',
    alertBoxColor: '',
  });
  const [requestToken, setRequestToken] = React.useState<string | null>(null);

  useEffect(() => {
    // Extract token from URL query params
    const urlParams = new URLSearchParams(location.search);
    setRequestToken(urlParams.get("token"));
  }, [location]);

  // Handle form data submission
  const handleFormData = (values: any, { setSubmitting, resetForm }: any) => {
  const endPoint = `/public/resetpassword?token=${requestToken}`;
  // Directly use `newPassword` for the API request while maintaining the original field name in the form
  postData(endPoint, {
    newPassword: values.password, // Map 'password' to 'newPassword'
    confirmPassword: values.confirmPassword, // Keep 'confirmPassword' as is
  }, undefined,false)
    .then((res: any) => {
      if (res.status === 200) {
        setAlertStatus(true);
        resetForm();
        setAlertMsg({
          message: "Password has been successfully reset.", // Hardcode a success message here
          alertBoxColor: "alert-success", // Success color
        });
        setSubmitting(false);
        setTimeout(() => {
          navigate("/"); // Redirect after success
        }, 2000);
      } else {
        // Handle unexpected status
        setAlertStatus(true);
        resetForm();
        setSubmitting(false);
        setAlertMsg({
          message: "An unexpected error occurred.", // Hardcode an error message for unexpected responses
          alertBoxColor: "alert-danger", // Error color
        });
      }
    })
    .catch((err) => {
      console.log(err);

      // Check if the error response contains a message and the status code is 400
      if (err.response && err.response.status === 400 && err.response.data && err.response.data.message) {
        // If status is 400, display the error message from the server
        setAlertStatus(true);
        setAlertMsg({
          message: err.response.data.message, // Use the raw error message from the 400 response
          alertBoxColor: "alert-danger", // Error color
        });
      } else {
        // For any other error, display a generic error message
        setAlertStatus(true);
        setAlertMsg({
          message: err.message || "An unexpected error occurred.", // Use err.message directly or a fallback message
          alertBoxColor: "alert-danger", // Error color
        });
      }

      setSubmitting(false);
    });
};


  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, t("validation.passwordMinLength"))
        .required(t("validation.passwordRequired")),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], t("validation.passwordsMustMatch"))
        .required(t("validation.passwordConfirmRequired")),
    }),
    onSubmit: handleFormData, // Use handleFormData for form submission
  });

  return (
    <>
    <div className="contentarea-wrapper">
      <h2 className="page-title db-title">{t("resetPassword.title")}</h2>
      <div className="line-lessmargin"></div><br/>

      {/* Display alert message based on submission result */}
      {alertStatus && (
        <div className={`alert ${alertMsg.alertBoxColor}`}>
          {alertMsg.message}
        </div>
      )}

      {/* Password reset form */}
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            {t("resetPassword.newPassword")}
          </label>
          <input
            id="password"
            name="password"
            type="password"
            className="form-control inputWidth"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            />
          {formik.touched.password && formik.errors.password && (
            <div className="text-danger">{formik.errors.password}</div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="confirmPassword" className="form-label">
            {t("resetPassword.confirmPassword")}
          </label>
          <input
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            className="form-control inputWidth"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className="text-danger">{formik.errors.confirmPassword}</div>
          )}
        </div>

        <button
          type="submit"
          className="btn btn-primary"
          disabled={formik.isSubmitting}
          >
          {formik.isSubmitting ? t("resetPassword.submitting") : t("resetPassword.resetPassword")}
        </button>
      </form>
    </div>
    </>
  );
};

export default ResetPassword;
