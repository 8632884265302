import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import TimerAlertBox from "../../widgets/alert/timerAlert";
import { putData } from "../../adapters/coreservices";

interface ChangePasswordModalProps {
  show: boolean;
  onHide: () => void;
  userData: {
    id: string;
    name: string;
    email: string;
  };
}

interface PasswordForm {
  newPassword: string;
  confirmPassword: string;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ show, onHide, userData }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm<PasswordForm>();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({ message: "", alertBoxColor: "" });

  const newPassword = watch("newPassword");

  const onSubmit = async (data: PasswordForm) => {
    setIsSubmitting(true);
    try {
      const endpoint = `/user/profile/${userData.id}/changepassword`;
      const payload = {
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword
      };
      const response = await putData(endpoint, payload);

      if (response?.status === 200) {
        Swal.fire({
          timer: 3000,
          width: "25em",
          color: "#666",
          icon: "success",
          background: "#e7eef5",
          showConfirmButton: false,
          text: "Password changed successfully!"
        });
        reset(); // Clear the form
        onHide(); // Close the modal
      } else {
        setShowAlert(true);
        setAlertMsg({
          message: "Failed to change password.",
          alertBoxColor: "danger"
        });
      }
    } catch (error: any) {
      console.error("Error changing password:", error);
      setShowAlert(true);
      setAlertMsg({
        message: error.response?.data?.message || "An unexpected error occurred.",
        alertBoxColor: "danger"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleHide = () => {
    reset(); // Clear the form
    setShowAlert(false); // Clear the alert
    onHide(); // Close the modal
  };

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Change Password
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <p>
            <strong>
              Name: {userData.name || "Unknown User"} <br />
              Email: {userData.email || "No Email Provided"}
            </strong>
          </p>
          {showAlert && (
            <TimerAlertBox
              alertMsg={alertMsg.message}
              className="mt-3"
              variant={alertMsg.alertBoxColor}
              setShowAlert={setShowAlert}
              showAlert={showAlert}
            />
          )}
          <Form.Group controlId="newPassword" className="mt-3">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              {...register("newPassword", {
                required: "New password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long"
                }
              })}
              isInvalid={!!errors.newPassword}
            />
            <Form.Control.Feedback type="invalid">
              {errors.newPassword?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="confirmPassword" className="mt-3">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              {...register("confirmPassword", {
                required: "Please confirm your password",
                validate: (value) =>
                  value === newPassword || "Passwords do not match"
              })}
              isInvalid={!!errors.confirmPassword}
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHide} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Changing..." : "Change Password"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
